import { bootstrapApplication } from '@angular/platform-browser';
import { environment } from '@mzic/mzic-environments';
import posthog from 'posthog-js';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

posthog.init(environment.posthogKeyProjectLegal, {
  api_host: environment.posthogHost,
  person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
  capture_pageview: false,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err),
);
