import { CommonModule } from '@angular/common';
import { Component, effect, OnDestroy, OnInit, signal } from '@angular/core';
import { Router, RouterLink, RoutesRecognized } from '@angular/router';
import { TranslocoModule, TranslocoService } from '@jsverse/transloco';
import { environment } from '@mzic/mzic-environments';
import { LanguageService } from '@mzic/mzic-services';
import posthog from 'posthog-js';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mzic-header',
  standalone: true,
  imports: [CommonModule, RouterLink, TranslocoModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  documentActive = '';
  languageActive = '';
  routeSubscription: Subscription | undefined;

  protected openMenu = signal(false);

  constructor(
    private languageService: LanguageService,
    private route: Router,
    private translocoService: TranslocoService,
  ) {
    effect(() => {
      if (this.openMenu()) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
      }

      this.languageActive = this.languageService.languageState();
    });
  }

  ngOnDestroy(): void {
    this.routeSubscription?.unsubscribe();
  }

  ngOnInit(): void {
    this.routeSubscription = this.route.events.subscribe((data) => {
      if (data instanceof RoutesRecognized) {
        // Marca qual página está ativa no menu
        this.documentActive = data.url.endsWith('terms-of-use')
          ? 'terms-of-use'
          : 'privacy';
      }
    });
  }

  goHome() {
    this.openMenu.set(false);
    posthog.capture('home_logo_clicked', { button_name: 'Home' });
    window.location.href = environment.mainUrl;
  }

  protected handleMenuClick() {
    this.openMenu.update((value) => !value);
  }

  switchLanguage(language: 'en' | 'pt-br') {
    this.languageService.languageState.set(language);
    this.translocoService.setActiveLang(language);
  }
}
