import { HttpClientModule } from '@angular/common/http';
import { Component, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import posthog from 'posthog-js';
import { HeaderComponent } from './components/header/header.component';

@Component({
  standalone: true,
  selector: 'mzic-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  imports: [RouterModule, HeaderComponent, HttpClientModule],
})
export class AppComponent {
  router = inject(Router);
  
  constructor() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        posthog.capture('$pageview');
      }
    });
  }
}
